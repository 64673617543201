import Actions from '../lib/Actions'
import Dispatcher from '../dispatchers/Dispatcher'

export default class OpenThrowing {
  static toggle = (action, value) => {
    Dispatcher.dispatch({
      type: action,
      data: value
    })
  }

  static evalHitAction = (player, team, scoringStyle, pts) => {
    if (scoringStyle === 'Individual') {
      const hitData = { playerId: player.id, teamId: team.id, score: (player.singleScore + pts) }
      this.toggle(Actions.EDIT_PLAYER_SCORE, hitData)
    } else {
      const hitData = { teamId: team.id, score: (team.singleScore + pts) }
      this.toggle(Actions.EDIT_TEAM_SCORE, hitData)
    }
  }

  static getPlayerDisplayScore = (playerData) => {
    return playerData.singleScore + ' pts'
  }

  static getTeamDisplayScore = (teamData) => {
    return teamData ? ` - ${teamData.singleScore}` : ''
  }
}

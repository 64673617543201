import React from 'react';
import ReactPlayer from 'react-player/lazy'
// import Lane from '../lib/Lane';
import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Request from '../lib/Request';
import Storage from '../lib/Storage';
import Endpoints from '../lib/Endpoints';
import Reservation from '../lib/Reservation';
import Dispatcher from '../dispatchers/Dispatcher';

import Game from '../lib/Game';
import Lane from '../lib/Lane';
import FirstTo21 from '../lib/FirstTo21';
import BucketList from '../lib/BucketList';
import MagicNumber from '../lib/MagicNumber';
import AroundTheWorld from '../lib/AroundTheWorld';
import ModalScoreMessage from './ModalScoreMessage';

import { createConsumer } from '@rails/actioncable';

export default class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            logScore: null,
            points: null,
            popClass: null,
            shownHouseRules: null,
            minutesRemaining: null,
            haveSeenTimeWarning: null,
            haveAgreedTimeWarning: null,
            haveSeenTimeout: null,
            haveOverridenTimeout: null,
            previousEventTime: null,
            previousGameState: {},
            previousGameStateResponse: null,
            cableAttached: false
        };
    }

    toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    fetchReservationLoop = () => {
        setInterval(() => {
            if (!this.props.app.reservationNumber) {
                try {
                    Reservation.load(this.props.app.locationId, this.props.app.laneNumber);
                    Lane.getHitMessages(this.props.app.locationId);
                    Lane.getHouseRules(this.props.app.locationId);
                } catch (err) {
                    console.log('Error fetching reservation data');
                }
            }
        }, 15000);
    }

    /**
     * Run an interval to check if we have a reservation number in order to attach the
     * correct cable (by reservation number) and fetch the last game state response so
     * the tv is in-sync with the tablet in case the tv refreshes while a game is in progress.
     */
    fetchInitialGameStateLoop = () => {
        window.initialGameStateLoopInterval = window.setInterval(() => {
            // if we have a reservation number and no game state response, fetch one for initial load
            if (this.props.app.reservationNumber) {

                if (!this.state.cableAttached) {
                    this.fetchGameStateLoop();
                }

                if (!this.state.previousGameStateResponse) {
                    this.fetchGameStateRequest();
                } else {
                    window.clearInterval(window.initialGameStateLoopInterval);
                }
            }
        }, 2000);
    }

    fetchGameStateRequest = () => {
        Request.get(Endpoints.fetchGameState(this.props.app.reservationNumber, this.props.app.laneNumber)).then((response) => {
            this.updateGameStateData(response);
        });
    }

    /**
     * Check for cancellations by seeing if we have a current reservationNumber
     * and the response from the server is "not found"
     * @param {*} response response from booking app
     */
    checkForCancellation = (response) => {
        if (this.props.app.reservationNumber && response.data.message && response.data.message === "No reservation") {
            window.location.reload(false);
        }
    };


    /**
     * Check if the reservation has switched lanes.
     * If our lane is no longer in the list of lanes, reload the app
     * 
     * @param {*} response response from booking app
     * @param {*} laneNumber current lane number
     */
    checkLaneChange = (response, laneNumber) => {
        if (response.data.data.laneNumbers && response.data.data.laneNumbers.length > 0 ) {
            if (!response.data.data.laneNumbers.includes(laneNumber)) {
                window.location.reload(false);
            }
        }
    }

    reservationStatusLoop = () => {
        let timeRemaining = null;
        let endTimestamp, prevEndTimestamp, endTime, laneNumber = null;
        let reset = false;
        setInterval(() => {
            laneNumber = parseInt(Storage.getLaneNumber());

            if (this.props.app.reservationNumber) {
                // If we have not moved into the gameplay stage, keep checking for training statuses
                if (!this.props.app.startedTraining || !this.props.app.completedTraining) {
                    this.setState({ shownHouseRules: true });
                    Request.get(Endpoints.reservationStatus(this.props.app.reservationNumber)).then((response) => {
                        Dispatcher.dispatch({
                            type: Actions.UPDATE_RESERVATION_STATUS,
                            data: response.data.data
                        });
                        
                        // Update welcome message
                        if (response.data.data.welcomeMessage && response.data.data.welcomeMessage !== this.props.welcomeMessage) {
                            this.toggle(Actions.WELCOME_MESSAGE, response.data.data.welcomeMessage);
                        }

                        this.checkLaneChange(response, laneNumber);
                    }).catch(err => {
                        this.checkForCancellation(err.response);
                    });
                    
                // Time left in reservation logic
                } else {
                    // console.log('have completed training');
                    Request.get(Endpoints.reservationStatus(this.props.app.reservationNumber)).then((response) => {
                        // console.log('requesting status');
                        if(response.data.data.estimatedEnd !== null && response.data.data.estimatedEnd !== undefined) {
                            endTimestamp = new Date(response.data.data.estimatedEnd);
                            if ( !prevEndTimestamp || endTimestamp.getTime() !== prevEndTimestamp.getTime()) {
                                reset = true;
                                prevEndTimestamp = endTimestamp;
                                endTime = new Date(endTimestamp);
                                // console.log('unsetting all state');
                                this.setState({haveSeenTimeWarning: false});
                                this.setState({haveAgreedTimeWarning: false});
                                this.setState({haveSeenTimeout: false});
                                this.setState({haveOverridenTimeout: false});
                                this.toggle(Actions.MODAL_TIME_WARNING, false);
                                this.toggle(Actions.SHOWN_TIME_WARNING, false);
                                this.toggle(Actions.RESERVATION_TIMEOUT, false);
                            } else {
                                // prevents race condition
                                if (!reset) {
                                    // let gameState = Reservation.processData(response2);
                                    let gameState = Reservation.processData(this.state.previousGameStateResponse);
                                    // PROCESS TIME WARNINGS/LOCKOUTS
                                    if (gameState.shownTimeWarning) {
                                        if (!this.state.haveSeenTimeWarning) {
                                            this.toggle(Actions.MODAL_TIME_WARNING, true);
                                            this.setState({ haveSeenTimeWarning: true });
                                        } else if (gameState.agreedTimeWarning && !this.state.haveAgreedTimeWarning) {
                                            this.toggle(Actions.AGREED_TIME_WARNING, true);
                                            Display.toggleTimeWarningModal(false);
                                            this.setState({ haveAgreedTimeWarning: true });
                                        }
                                    }
                                    if (gameState.reservationTimeout) {
                                        if (!this.state.haveSeenTimeout) {
                                            this.toggle(Actions.RESERVATION_TIMEOUT, true);
                                            this.setState({ haveSeenTimeout: true });
                                        } else if (gameState.reservationExtended && !this.state.haveOverridenTimeout) {
                                            Lane.extendReservation();
                                            this.setState({ haveOverridenTimeout: true });
                                        }
                                    }
                                }
                                reset = false;
                            }

                            timeRemaining = Math.floor((endTime-Date.now())/1000/60) //off by two minutes for some reason - assumed rounding error
                            if (timeRemaining > 0) {
                                this.setState({minutesRemaining: timeRemaining});
                            } else if (this.state.haveOverridenTimeout) {
                                this.setState({minutesRemaining: 0});
                            } else {
                                this.setState({minutesRemaining: '< 1'});
                            }
                        }
                    }).catch(err => {
                        // it would be better if this didn't 404 so we can check the 
                        // response in a cleaner way.
                        this.checkForCancellation(err.response);
                    });
                }
            }
        }, 5000);
    }

    /**
     * Attach our component to action cable channel based on the reservation number. When data is received it will pass it off
     * to `updateGameStateData` which is what actually parses the data.
     */
    fetchGameStateLoop = () => {

        // assign which callback to use inside the cable since the scope changes when defining the received function
        const callback = this.updateGameStateData;

        console.log('Attaching game state cable...');

        // create the consumer based on the reservation number since that is hooked up to the booking->game state object
        createConsumer(Endpoints.CABLE_URL).subscriptions.create({ channel: 'GameStateChannel', reservation_number: this.props.app.reservationNumber, lane_number: this.props.app.laneNumber }, {
            received(data) {
                console.log('Received new data from the game state cable: ', data);
                // this is a nested object to mimic axios responses since the data is passed off into several helpers
                callback({ data: data });
            }
        });

        this.setState({ cableAttached: true });
    }

    /**
     * Take the response of an action cable dispatch and parse the updated game state data
     *
     * @param response action cable data payload
     */
    updateGameStateData = (response) => {
        this.setState({ previousGameStateResponse: response });

        const prevState     = this.state.previousGameState;
        const prevEventTime = this.state.previousEventTime;
        const gameState     = Reservation.processData(response);
        const gameData      = Reservation.processGameData(response);

        // Check if reservation has been ended before doing anything else
        if (response.data.data.reservationCompleted && response.data.data.reservationCompleted === true) {
            window.location.reload(false);
        }

        if (gameState !== false && (JSON.stringify(prevState) !== JSON.stringify(gameState)) ) {

            this.setState({ previousGameState: gameState });

            Dispatcher.dispatch({
                type: Actions.UPDATE_GAME_STATE,
                data: gameState
            });

            Display.toggleLeagueScoreboard(gameState.readyToPlay);
            Display.hideWinnerModal(true);
        }

        // PROCESS HITS
        if (gameState.throwData) {
            let eventData = gameState.throwData;
            let eventTime = gameState.throwData.timestamp;

            if (eventTime !== null && eventTime !== prevEventTime) {
                Display.hideWinnerModal(true);
                this.setState({logScore: true, points: eventData.points});
                Display.toggleShowHitModal(true);
                setTimeout( () => {
                    if (gameState.game === 'League Style') {
                        Game.createHitForPlayer(eventData.currentThrower, eventData.points, eventData.gameState, gameState.scoringStyle);
                    } else if (gameState.game === 'First to 21/51') {
                        FirstTo21.evalHitAction(eventData.currentThrower, eventData.currentTeam, gameState.scoringStyle, gameState.bustOrStay, gameState.maxScore, eventData.points);
                    } else if (gameState.game === 'Bucket List') {
                        BucketList.evalHitAction(eventData.currentThrower, eventData.currentTeam, gameState.scoringStyle, gameState.bucketListBlueBalls, eventData.points);
                    } else if (gameState.game === 'Magic Number') {
                        MagicNumber.evalHitAction(eventData.currentThrower, eventData.currentTeam, gameState.scoringStyle, gameState.magicNumberBlueBalls, eventData.points, gameData);
                    } else if (gameState.game === 'Around the World') {
                        AroundTheWorld.evalHitAction(eventData.currentThrower, eventData.currentTeam, gameState.scoringStyle, gameState.aroundTheWorldBlueBalls, eventData.points);
                    }

                    this.setState({ previousEventTime: eventTime });

                    Display.toggleShowHitModal(false);
                }, 1500);
            }
        }
    }

    componentDidMount() {
        this.fetchInitialGameStateLoop();
        this.fetchReservationLoop();
        this.reservationStatusLoop();
        if(this.props.app.locationId) {
            try {
                Reservation.load(this.props.app.locationId, this.props.app.laneNumber);
                Lane.getHitMessages(this.props.app.locationId);
                Lane.getHouseRules(this.props.app.locationId);
            } catch (err) {
                console.log('Error fetching reservation data');
            }
        }
        // this.fetchGameStateLoop();

        // setTimeout(function() { 
        //     alert('load test')
        //     // document.getElementById('elementID').click();
        //     var elements = document.getElementsByClassName('marketing');
        //     var requiredElement = elements[0];
        //     requiredElement.click();
        //     alert('clicked')
        // }.bind(this), 4000)
    }

    formatReservationName = () => {
        if (this.props.app.welcomeMessage) {
            return this.props.app.welcomeMessage;
        } else {
            let lastName = this.props.app.reservationName.split(' ').pop();
            return "Welcome " + lastName + " Party!";
        }
    }

    showHome = () => {
        if (this.props.display.showBrowseGames || this.props.app.groupInLane) {
            return false;
        }

        return true;
    }

    showWelcome = () => {
        return this.props.app.startedTraining
    }

    evalHomeScreen = () => {
        if (!this.props.app.reservationNumber) {
            if (this.props.app.adVideo) {
                return (
                    <div className="videoWrapper">
                        <ReactPlayer 
                            className='marketing'
                            url={`${this.props.app.adVideo}`}
                            loop={true}
                            playing={true}
                            volume={0}
                            muted={true}
                            width='100%'
                            height='100%'
                        /> 
                    </div>
                )
            } else { return null }
        } else if (!this.props.app.startedTraining) {
            return this.formatReservationName();
        } else if (this.props.app.startedTraining && !this.props.app.completedTraining) {
            return (<p dangerouslySetInnerHTML={{
                __html: this.props.app.houseRules
            }}></p>);
        } else {
            return (<h1 dangerouslySetInnerHTML={{
                __html: "Waiting for game to start"
            }}></h1>);
        }
    }

    evalTimeRemaining = () => {
        if (this.state.minutesRemaining) {
            return `${this.state.minutesRemaining} minutes`
        } else {
            return '';
        }
    }

    render() {
        return (
            <>
            <div id="home" className={`${this.showHome() ? "" : "inactive"}`}>
                {/* <div id="lane">{this.props.app.reservationName ? this.formatReservationName() : `Marketing shite`}</div> */}

                <div id="lane">
                    { this.evalHomeScreen() }
                </div>

            </div>

            <ModalScoreMessage 
                // {...this.props} 
                gameType={this.props.game.game}
                message={this.state.previousGameState.modalScoreMessage}
                game={this.props.game ? true : false}
                currentThrower={this.props.game && this.props.game.currentThrower ? this.props.game.currentThrower : null}
                // throwCount={this.props.game && this.props.game.throwCount ? this.props.game.throwCount : null}
                showHitModal={this.props.display.showHitModal}
                cancelLogScore={this.cancelLogScore}
                logScore={this.state.logScore} 
                points={this.state.points} />

            { this.state.haveSeenTimeWarning && 
                <div id="remaining-time">
                    {/* <h2>{this.state.minutesRemaining} minutes</h2> */}
                    <h2>{this.evalTimeRemaining()}</h2>
                </div>
            }
            </>
        )
    }

}

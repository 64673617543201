import React from 'react';
import { Container } from 'flux/utils';
import '../scss/styles.scss';
import AppStore from '../stores/AppStore';
import GameStore from '../stores/GameStore';
import DisplayStore from '../stores/DisplayStore';

import Lane from '../lib/Lane';
import Display from '../lib/Display';
import Reservation from '../lib/Reservation'
import Target from '../components/Target';
import Brand from '../components/Brand';
import Home from '../components/Home';
import GameSetup from '../components/GameSetup';
import Scoreboard21 from '../components/Scoreboard21';
import ScoreboardLeague from '../components/ScoreboardLeague';
import ScoreboardBucketList from '../components/ScoreboardBucketList';
import ScoreboardMagicNumber from '../components/ScoreboardMagicNumber';
import ScoreboardAroundTheWorld from '../components/ScoreboardAroundTheWorld';
import ScoreboardTicTacToe from '../components/ScoreboardTicTacToe';
import ScoreboardOpenThrowing from '../components/ScoreboardOpenThrowing';
import ModalAdmin from '../components/ModalAdmin';
import ModalKeypad from '../components/ModalKeypad';
import ModalTimeWarning from '../components/ModalTimeWarning';
import ModalReservationTimeout from '../components/ModalReservationTimeout';
import LaneInfo from '../components/LaneInfo';

function getStores() {
    return [
        AppStore,
        GameStore,
        DisplayStore
    ];
}

function getState() {
    const state = {
        app: AppStore.getState(),
        game: GameStore.getState(),
        display: DisplayStore.getState()
    };

    return state;
}

function App(props) {

    // We want to make sure we have time to fetch the branded images
    // before rendering anything so there isn't a flicker while the
    // logos change
    if (!props.app.initialFetch && props.app.locationId != null && props.app.laneNumber != null && !window.initialFetch) {
        if (!props.app.reservationNumber) {
            console.log('Initial reservation load');
            Reservation.load(props.app.locationId, props.app.laneNumber);
            window.initialFetch = true; // since this will return true a bunch of times, this make sure we only request it once and wait.
            Reservation.loadAssets(props.app.locationId);
        }
        return <div />
    }

    return (
        <div id="app" className={`${props.game.readyToPlay ? "game-target" : ""} ${props.display.showLeagueScoreboard ? "view-scores" : ""}`}>
            <div id="wood"></div>
            <Target {...props} />
            <div id="overlay"></div>
            <Brand logo={props.app.appLogo} reservation={props.app.reservationNumber} reservationStarted={props.app.reservationStarted} />
            
            <div id="interface">
                <GameSetup {...props} />
            </div>

            <Home {...props} />

            { props.game.game === 'League Style' && <ScoreboardLeague {...props} /> }
            { props.game.game === 'First to 21/51' && <Scoreboard21 {...props} /> }
            { props.game.game === 'Bucket List' && <ScoreboardBucketList {...props} /> }
            { props.game.game === 'Magic Number' && <ScoreboardMagicNumber {...props} /> }
            { props.game.game === 'Around the World' && <ScoreboardAroundTheWorld {...props} /> }
            { props.game.game === 'Open Throwing' && <ScoreboardOpenThrowing {...props} /> }
            { props.game.game === 'Tic Tac Toe' && <ScoreboardTicTacToe {...props} /> }

            <ModalAdmin
                reservationNumber={props.app.reservationNumber}
                groupInLane={props.app.groupInLane}
                showAdminModal={props.display.showAdminModal}
                location={props.app.location}
                lane={props.app.lane} />
                
            <ModalKeypad {...props} />

            <ModalTimeWarning showModal={props.display.showModalTimeWarning} />

            <ModalReservationTimeout showModal={props.display.showReservationTimeout} />

            <LaneInfo laneNumber={props.app.laneNumber} />

            <div id="axearcade">
                <img src="images/axe-arcade.svg" alt="logo-svg" 
                    onClick={() =>  {
                        Lane.toggleAccessingAdmin(true);
                        Display.toggleModalKeypad(true);
                    }}
                />
            </div>

         </div>
    );
}

export default Container.createFunctional(App, getStores, getState);

/**
 * Class containing main API endpoints used by `Request`.
 */
export default class Endpoints {

    // static BASE_URL = 'https://reqres.in/api';
    // static users() {
    //     return `${this.BASE_URL}/users`;
    // }

    /**
 * URL to the API
     */
    static API_URL = process.env.REACT_APP_API_URL;

    static CABLE_URL = process.env.REACT_APP_CABLE_URL;

    static getResource(locationId, resourceId) {
        return `${this.API_URL}/locations/${locationId}/resources/by_order/${resourceId}`;
    }

    /**
     * Get game rules
     */
    static getGameRules() {
        return `${this.API_URL}/game_rules`;
    }

    static getEmployeeCodes(locationId) {
        return `${this.API_URL}/employee_codes?location_id=${locationId}`;
    }

    /**
     * Get a location's hit messages
     */
    static getHitMessages(locationId) {
        return `${this.API_URL}/messages?location_id=${locationId}`;
    }

    /**
     * Get a location's house rules
     */
    static getHouseRules(location_id) {
        return `${this.API_URL}/house_rules?location_id=${location_id}`;
    }

    /**
     * Get a reservation
     */
    static getReservation(location_id, lane_number) {
        return `${this.API_URL}/reservation_details?location_id=${location_id}&lane_number=${lane_number}`;
    }
    
    static getLocationAssets(location_id) {
        return `${this.API_URL}/location_assets?location_id=${location_id}`;
    }

    static completeTraining(reservation_number) {
        return `${this.API_URL}/complete_training?reservation_number=${reservation_number}`;
    }

    static startReservation(reservation_number) {
        return `${this.API_URL}/start_reservation?reservation_number=${reservation_number}`;
    }

    static endGame(reservation_number, lane_number) {
        return `${this.API_URL}/game_end?reservation_number=${reservation_number}&lane_number=${lane_number}`;
    }

    static sendGameState(reservation_number, lane_number) {
        return `${this.API_URL}/game_state?reservation_number=${reservation_number}&lane_number=${lane_number}`;
    }

    static fetchGameState(reservation_number, lane_number) {
        return `${this.API_URL}/game_state?reservation_number=${reservation_number}&lane_number=${lane_number}`;
    }

    /**
     * TV Actions
     */
    static reservationStatus(reservation_number) {
        return `${this.API_URL}/reservation_status?reservation_number=${reservation_number}`;
    }
}

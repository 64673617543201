import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Dispatcher from '../dispatchers/Dispatcher';

export default class FirstTo21 {

    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static show21WinModal = () => {
        Display.toggle21WinModal(true);
        setTimeout( () => {
            Display.toggle21WinModal(false);
        }, 1500);
    }

    static showBustResetModal = () => {
        Display.toggleBustResetModal(true);
        setTimeout( () => {
            Display.toggleBustResetModal(false);
        }, 1500);
    }

    static showBustStayModal = () => {
        Display.toggleBustStayModal(true);
        setTimeout( () => {
            Display.toggleBustStayModal(false);
        }, 1500);
    }

    static evalHitAction = (player, team, scoringStyle, bustOrStay, maxScore, pts) => {
        if (scoringStyle === 'Individual') {
            if (player.singleScore + pts === maxScore) {
                this.show21WinModal()
            } else if (player.singleScore + pts > maxScore) {
                if (bustOrStay === 'Bust') {
                    this.showBustResetModal()
                } else {
                    this.showBustStayModal()
                }
            }
        } else {
            if (team.singleScore + pts === maxScore) {
                this.show21WinModal()
            }
            else if (team.singleScore + pts > maxScore) {
                if (bustOrStay === 'Bust') {
                    this.showBustResetModal()
                } else {
                    this.showBustStayModal()
                }
            }
        }
    }

    static editTempScoreForPlayer = (playerId, pts) => {
        let hitData = { playerId: playerId, score: pts }
        this.toggle(Actions.TEMP_EDIT_PLAYER_SCORE, hitData);
    }

    static editTempScoreForTeam = (teamId, pts) => {
        let hitData = { teamId: teamId, score: pts }
        this.toggle(Actions.TEMP_EDIT_TEAM_SCORE, hitData);
    }

    static editTempThrowsForPlayer = (playerId, throws) => {
        let hitData = { playerId: playerId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_PLAYER_THROWS, hitData);
    }

    static editTempThrowsForTeam = (teamId, throws) => {
        let hitData = { teamId: teamId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_TEAM_THROWS, hitData);
    }

    static getPlayerDisplayScore = (playerData, maxScore) => {
        return playerData.singleScore + `/ ${maxScore} pts`
    }

    static getTeamDisplayScore = (teamData, maxScore) => {
        return teamData ? ` - ${teamData.singleScore} / ${maxScore}` : '';
    }

    static playerGameStatus = (playerData, scoringStyle, teamData, maxScore) => {
        if (scoringStyle === 'Individual') {
            return playerData.singleScore === maxScore;
        } else {
            return teamData[playerData.teamId-1].singleScore === maxScore;
        }
    }
}
import React from 'react'
import Game from '../lib/Game'
import Display from '../lib/Display'
import Modal18Keypad from './Modal18Keypad'
import ModalThrowCount from './ModalThrowCount'
import AroundTheWorld from '../lib/AroundTheWorld'
import AroundTheWorldPlayerScores from './AroundTheWorldPlayerScores'

export default class ScoreboardAroundTheWorld extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      playerId: null,
      teamId: null,
      score: null,
      throwNumber: null,
      throwCount: null,
      editingScore: null,
      showThrowModal: null
    }
  }

  toggleThrowModal = (val) => {
    this.setState({ showThrowModal: val })
  }

  renderTeams = (gameState) => {
    if (gameState.length > 0) {
      return gameState.map((team, index) => {
        return (
          <tbody key={index}>
            {this.props.game.scoringStyle !== 'Individual' &&
              <tr className="player">
                <th>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                <td className='td-bl' onClick={() => this.setTeamToEditThrows(team.id)}>{team.throwCount}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(0, team)}>{AroundTheWorld.getAroundTheWorldScore(0, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(1, team)}>{AroundTheWorld.getAroundTheWorldScore(1, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(2, team)}>{AroundTheWorld.getAroundTheWorldScore(2, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(3, team)}>{AroundTheWorld.getAroundTheWorldScore(3, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(4, team)}>{AroundTheWorld.getAroundTheWorldScore(4, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(5, team)}>{AroundTheWorld.getAroundTheWorldScore(5, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(6, team)}>{AroundTheWorld.getAroundTheWorldScore(6, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(7, team)}>{AroundTheWorld.getAroundTheWorldScore(7, team)}</td>
                <td className='td-bl' onClick={() => this.editTeamScore(8, team)}>{AroundTheWorld.getAroundTheWorldScore(8, team)}</td>
                { this.props.game.aroundTheWorldBlueBalls && <td className='td-bl' onClick={() => this.editTeamScore(9, team)}>{AroundTheWorld.getAroundTheWorldScore(9, team)}</td> }
              </tr>
            }
            {this.props.game.scoringStyle === 'Individual' && team && this.renderPlayerScores(team.players)}
          </tbody>
        )
      })
    }
  }

  renderPlayerScores = (gameState) => {
    return gameState.map((player, index) => {
      return (
        <AroundTheWorldPlayerScores
          setPlayerToEdit={this.setPlayerToEdit}
          setPlayerToEditThrows={this.setPlayerToEditThrows}
          editPlayerScore={this.editPlayerScore}
          name={player.name}
          blueBalls={this.props.game.aroundTheWorldBlueBalls}
          playerData = {player}
          id={player.id}
          key={index} />
      )
    })
  }

  setPlayerToEdit = (playerId) => {
    this.setState({playerId: playerId})
  }

  editPlayerScore = (aroundTheWorldIndex, playerData) => {
    if (aroundTheWorldIndex > 0 && playerData.aroundTheWorld[aroundTheWorldIndex -1] === 0) { return }
    if (playerData.aroundTheWorld[aroundTheWorldIndex + 1] === 1) { return }

    AroundTheWorld.editTempScoreForPlayer(aroundTheWorldIndex, playerData)
  }

  setPlayerToEditThrows = (playerId) => {
    this.setState({playerId: playerId})
    this.toggleThrowModal(true)
  }

  editTeamScore = (aroundTheWorldIndex, teamData) => {
    if (aroundTheWorldIndex > 0 && teamData.aroundTheWorld[aroundTheWorldIndex -1] === 0) { return }
    if (teamData.aroundTheWorld[aroundTheWorldIndex + 1] === 1) { return }

    AroundTheWorld.editTempScoreForTeam(aroundTheWorldIndex, teamData)
  }

  setTeamToEditThrows = (teamId) => {
    this.setState({teamId: teamId})
    this.toggleThrowModal(true)
  }

  updateScoresAndClose = () => {
    Game.setGameStateToModified()

    if (this.props.game.scoringStyle === 'Individual') {
      AroundTheWorld.updatePlayerAroundTheWorldScoreAfterEdit()
    } else {
      AroundTheWorld.updateTeamAroundTheWorldScoreAfterEdit()
    }

    Display.toggleLeagueScoreboard(false)

    setTimeout( () => {
      Game.sendGameState(this.props.app, this.props.game)
    }, 1500)
  }

  editThrows = (throws) => {
    // If the score is >= 0, we know it's a real score.
    if (throws >= 0) {
      if (this.props.game.scoringStyle === 'Individual') {
        AroundTheWorld.editTempThrowsForPlayer(this.state.playerId, throws)
      } else {
        AroundTheWorld.editTempThrowsForTeam(this.state.teamId, throws)
      }
    }

    this.toggleThrowModal(false)
  }

  render() {
    let stateType

    if (!this.props.game.modifyGameState) {
      stateType = this.props.game.gameState
    } else {
      stateType = this.props.game.modifyGameState
    }

    return (
      <>
        <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
          <div id='score-grid' className="score-grid">
            <table>
              <thead>
                <tr>
                  <th></th><th>Throws</th><th>1</th><th>2</th><th>3</th>
                  <th>4</th><th>Bullseye</th><th>4</th><th>3</th><th>2</th><th>1</th>
                  { this.props.game.aroundTheWorldBlueBalls && <th>Blue Ball</th> }
                </tr>
              </thead>
              {this.renderTeams(stateType)}
            </table>
          </div>

          { this.props.game.scoringStyle !== 'Individual' && this.props.game.currentThrower &&
            <h1 style={{fontSize: ".4rem", marginBottom: ".3rem"}}>
              Current Thrower: {this.props.game.currentThrower.name}
            </h1>
          }

          <div id="lane-number" style={{bottom: ".6rem"}}><h2>{this.props.game.game}</h2></div>

        </div>

        <Modal18Keypad
          showModalKeypad18={this.props.display.showModalKeypad18}
          editPlayerScore={this.editPlayerScore} />

        <ModalThrowCount
          showModal={this.state.showThrowModal}
          toggleModal={this.toggleThrowModal}
          editThrows={this.editThrows} />
      </>
    )
  }
}

/**
 * Class containing the individual Dispatcher actions that are used throughout the site.
 */
export default class Actions {

    /**
     * Setting the initial fetch for brand images
     */
    static SET_INITIAL_FETCH = 'set-initial-fetch'

    /**
     *  Set the location id
     */ 
    static SET_LOCATION_ID = 'set-location-id';

    /**
     *  Set the lane number
     */ 
    static SET_LANE_NUMBER = 'set-lane-number';

    /**
     *  Set the app logo
     */ 
    static SET_APP_LOGO = 'set-app-logo';

    /**
     *  Reset the app logo
     */ 
    static RESET_APP_LOGO = 'reset-app-logo';

    /**
     *  Set the target image
     */ 
    static SET_TARGET_IMAGE = 'set-taget-image';

    /**
     *  Reset the target image
     */ 
    static RESET_TARGET_IMAGE = 'reset-taget-image';
    

    static UPDATE_GAME_STATE = 'update-game-state';

    /**
     *  Set the game rules from the server
     */ 
    static SET_GAME_RULES = 'set-game-rules';

    /**
     *  Set the employee codes from the server
     */ 
    static SET_EMPLOYEE_CODES = 'set-employee-codes';

    /**
     *  Open the browse games slide in
     */ 
    static GROUP_IN_LANE = 'group-in-lane';

    /**
     *  Started training for a group
     */ 
    static STARTED_TRAINING = 'started-training';

    /**
     *  Completed training
     */ 
    static COMPLETED_TRAINING = 'completed-training';

    /**
     *  Mark reservation as started
     */ 
    static START_RESERVATION = 'start_reservation';

    /**
     * Set hit messges from the server
     */
    static SET_HIT_MESSAGES = 'set-hit-messages';

    /**
     * Set house rules from the server
     */
    static SET_HOUSE_RULES = 'set-house-rules';

    /**
     *  Open the browse games slide in
     */ 
    static BROWSE_GAMES = 'browse-games';

    /**
     *  Set the game to show instructions for
     */ 
    static SET_INSTRUCTION_GAME = 'set-instruction-game';

    /**
     *  Set the current game for the App Store
     */ 
    static SET_CURRENT_GAME = 'set-current-game';

    /**
     *  Set the current game for the Game Store
     */ 
    static SET_GAME = 'set-game';

    /**
     *  Set the scoring style for the Game Store
     */ 
    static SET_SCORING_STYLE = 'set-scoring-style';

    /**
     *  Reset the entire game state
     */ 
    static RESET_GAME_STATE = 'reset-game-state';

    /**
     *  Show player setup
     */ 
    static PLAYER_SETUP = 'player-setup';

    /**
     *  Open the keypad modal
     */ 
    static MODAL_KEYPAD = 'modal-keypad';

    /**
     *  Open the 1-8 keypad modal
     */ 
    static MODAL_KEYPAD_18 = 'modal-keypad-18';

    /**
     *  Open the 21 keypad modal
     */ 
    static MODAL_KEYPAD_21 = 'modal-keypad-21';

    /**
     *  Open the time warning modal
     */ 
    static MODAL_TIME_WARNING = 'modal-time-warning';

    /**
     *  Lane has been shown the time warning modal
     */ 
    static SHOWN_TIME_WARNING = 'shown-time-warning';

    /**
     *  Lane has agreed to time warning
     */ 
    static AGREED_TIME_WARNING = 'agreed-time-warning';

    /**
     *  Open the instructions modal
     */ 
    static INSTRUCTIONS = 'instructions';

    /**
     *  Game is ready to play
     */ 
    static READY_TO_PLAY = 'ready-to-play';

    /**
     *  Show game setup
     */ 
    static GAME_SETUP = 'game-setup';

    /**
     *  Show game setup scoring
     */ 
    static GAME_SETUP_SCORING = 'game-setup-scoring';

    /**
     *  Show game setup team names
     */ 
    static GAME_SETUP_TEAM_NAMES = 'game-setup-team-names';

    /**
     *  Set team names
     */ 
    static SET_TEAM_NAMES = 'set-team-names';

    /**
     *  Set current thrower
     */ 
    static SET_CURRENT_THROWER = 'set-current-thrower';

    /**
     *  Set current team for logging hits
     */ 
    static SET_CURRENT_TEAM = 'set-current-team';

    /**
     *  Create a hit record for a specific player
     */ 
    static CREATE_HIT_FOR_PLAYER = 'create-hit-for-player';

    /**
     *  Create a temporaryhit record for a specific player
     */ 
    static CREATE_TEMP_HIT_FOR_PLAYER = 'create-temp-hit-for-player';

    /**
     *  Update/edit a specific throw for a specific player
     */ 
    static EDIT_TEMP_PLAYER_THROW = 'edit-temp-player-throw';

    /**
     *  Update/edit a specific throw for a specific player
     */ 
    static EDIT_PLAYER_THROW = 'edit-player-throw';

    /**
     *  Update/edit a player's score
     */ 
    static EDIT_PLAYER_SCORE = 'edit-player-score';

    /**
     *  Temp update/edit a player's score
     */ 
    static TEMP_EDIT_PLAYER_SCORE = 'temp-edit-player-score';

    /**
     *  Temp update/edit a player's throw count
     */ 
    static TEMP_EDIT_PLAYER_THROWS = 'temp-edit-player-throws';

    /**
     *  Update/edit a teams's score
     */ 
    static EDIT_TEAM_SCORE = 'edit-team-score';

    /**
     *  Temp update/edit a teams's score
     */ 
    static TEMP_EDIT_TEAM_SCORE = 'temp-edit-team-score';

    /**
     *  Temp update/edit a teams's throw count
     */ 
    static TEMP_EDIT_TEAM_THROWS = 'temp-edit-team-throws';

    /**
     *  Create a backup record of the game state to modify when modifying scores
     */ 
    static SET_MODIFY_GAME_STATE = 'set-modify-game-state';

    /**
     *  Update the currentThrower data for throw count after we have set the gamestate after editing scores
     */ 
    static UPDATE_PLAYER_THROW_COUNT_AFTER_EDIT = 'update-player-throw-count-after-edit';

    /**
     *  Update the currentThrower data for singleScore after we have set the gamestate after editing scores
     */ 
    static UPDATE_PLAYER_SCORE_AFTER_EDIT = 'update-player-score-after-edit';

    /**
     *  Update the currentTeam data for singleScore after we have set the gamestate after editing scores
     */ 
    static UPDATE_TEAM_SCORE_AFTER_EDIT = 'update-team-score-after-edit';

    /**
     *  Show hit modal
     */ 
    static HIT_MODAL = 'hit-modal';

    /**
     *  Show 21 WIN MODAL
     */ 
    static WIN_21_MODAL = 'win-21-modal';

    /**
     *  Show bust reset modal
     */ 
    static BUST_RESET_MODAL = 'bust-reset-modal';

    /**
     *  Show bust stay modal
     */ 
    static BUST_STAY_MODAL = 'bust-stay-modal';

    /**
     *  Show ring closed modal
     */ 
    static RING_CLOSED_MODAL = 'ring-closed-modal';

    /**
     *  Set the current game state to the one that has been modified
     */ 
    static SET_GAME_STATE_TO_MODIFIED = 'set-game-state-to-modified';

    /**
     * Scoreboard actions
     */
    static LEAGUE_SCOREBOARD = 'league-scoreboard';

    /**
     *  The admin is trying to access the admin tools
     */ 
    static ACCESSING_ADMIN = 'accessing-admin';

    /**
     *  Show admin modal
     */ 
    static ADMIN_MODAL = 'admin-modal';
    
    /**
     *  Choosing admin actions
     */ 
    static ADMIN_CHOOSE_ACTIONS = 'admin-choose-actions';

    /**
     * Assing a player to a team
     */
    static ASSIGN_PLAYER_TO_TEAM = 'assign-player-to-team';

    /**
     * Remove player from team
     */
    static REMOVE_PLAYER_FROM_TEAM = 'remove-player-from-team';

    /**
     * Set active team for assigning players
     */
    static SET_ACTIVE_TEAM = 'set-active-team';

    /**
     * Sort a player
     */
    static SORT_PLAYER = 'sort-player';

    /**
     * Load reservation
     */
    static LOAD_RESERVATION = 'load-reservation';

    /**
     * Update the reservation's welcome message
     */
    static WELCOME_MESSAGE = 'welcome-message';

    /**
     * Update reservation status
     */
    static UPDATE_RESERVATION_STATUS = 'update-reservation-status';

    /**
     * Set First to 21 game as 'bust' or 'stay'
     */
    static BUST_OR_STAY = 'bust-or-stay';

    /**
     * Set Bucket List game to include Blue Balls settings
     */
    static BUCKET_LIST_BLUE_BALLS = 'bucket-list-blue-balls';

    /**
     * Set Magic Number game to include Blue Balls settings
     */
    static MAGIC_NUMBER_BLUE_BALLS = 'magic-number-blue-balls';

    /**
     *  Show end game modal
     */ 
    static END_GAME_MODAL = 'end-game-modal';

    /**
     *  Set data for modal showing player status
     */
    static SET_PLAYER_STATUS_MODAL = 'set-player-status-modal'

    /**
     *  End the current game
     */ 
    static END_GAME = 'end-game';

    /**
     *  Assign the gameId from ending game to current game
     */ 
    static SET_GAME_ID = 'set-game-id';

    /**
     *  Play the same gam again
     */ 
    static PLAY_AGAIN = 'play-again'

    /**
     *  Choose a different game
     */ 
    static CHOOSE_NEW_GAME = 'choose-new-game'

    /**
     *  Store the current team setup
     */ 
    static STORE_TEAM_SETUP = 'store-team-setup'

    /**
     *  Copy existing team setup to current setup
     */ 
    static COPY_TEAM_SETUP = 'copy-team-setup'

    // BUCKET LIST GAME ACTIONS ?????????????????????????????????????????????????????????????????????

    /**
     *  Update/edit bucket score for a specific player
     */ 
    static EDIT_PLAYER_BUCKET_SCORE = 'edit-player-bucket-score';

    /**
     *  Temp update/edit a player's bucket score
     */ 
    static TEMP_EDIT_PLAYER_BUCKET_SCORE = 'temp-edit-player-bucket-score';

    /**
     *  Update/edit bucket score for a specific team
     */ 
    static EDIT_TEAM_BUCKET_SCORE = 'edit-team-bucket-score';

    /**
     *  Temp update/edit a teams's bucket score
     */ 
    static TEMP_EDIT_TEAM_BUCKET_SCORE = 'temp-edit-team-bucket-score';

    static UPDATE_PLAYER_BUCKET_SCORE_AFTER_EDIT = 'update-player-bucket-score-after-edit';

    static UPDATE_TEAM_BUCKET_SCORE_AFTER_EDIT = 'update-team-bucket-score-after-edit';


    // BUCKET LIST GAME ACTIONS ?????????????????????????????????????????????????????????????????????


    // MAGIC NUMBER GAME ACTIONS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

    /**
     *  Update/edit MAGIC score for a specific player
     */ 
    static EDIT_PLAYER_MAGIC_SCORE = 'edit-player-magic-score';

    /**
     *  Temp update/edit MAGIC score for a specific player
     */ 
    static TEMP_EDIT_PLAYER_MAGIC_SCORE = 'temp-edit-player-magic-score';
    
    /**
     *  After editing magic number scores, update the current players score to match new values
     */ 
    static UPDATE_PLAYER_MAGIC_SCORE_AFTER_EDIT = 'update-player-magic-score-after-edit';

    /**
     *  Update/edit MAGIC score for a specific player
     */ 
    static LOG_PLAYER_MAGIC_SCORE = 'log-player-magic-score';

    // MAGIC NUMBER GAME ACTIONS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

    // AROUND THE WORLD GAME ACTIONS ?????????????????????????????????????????????????????????????????????

    /**
     *  Update/edit around the world score for a specific player
     */
    static EDIT_PLAYER_AROUND_THE_WORLD_SCORE = 'edit-player-around-the-world-score';

    /**
      *  Temp update/edit a player's around the world score
      */
    static TEMP_EDIT_PLAYER_AROUND_THE_WORLD_SCORE = 'temp-edit-player-around-the-world-score';

    /**
      *  Update/edit around the world score for a specific team
      */
    static EDIT_TEAM_AROUND_THE_WORLD_SCORE = 'edit-team-around-the-world-score';

    /**
      *  Temp update/edit a teams's around the world score
      */

    static TEMP_EDIT_TEAM_AROUND_THE_WORLD_SCORE = 'temp-edit-team-around-the-world-score';

    static UPDATE_PLAYER_AROUND_THE_WORLD_SCORE_AFTER_EDIT = 'update-player-around-the-world-score-after-edit';

    static UPDATE_TEAM_AROUND_THE_WORLD_SCORE_AFTER_EDIT = 'update-team-around-the-world-score-after-edit';

     // AROUND THE WORLD GAME ACTIONS ?????????????????????????????????????????????????????????????????????

    // LEAGUE STYLE EVALUATE END OF GAME
    /**
     *  Set the end game messaging for League Style
     */ 
    static WINNER_MESSAGE = 'winner-message';

    /**
     *  Hide the winner modal
     */ 
    static HIDE_WINNER_MODAL = 'hide-winner-modal';

    /**
     * Reservation time has ended
     */
    static RESERVATION_TIMEOUT = 'reservation-timeout'

    static ADMIN_OVERRIDE_TIMEOUT = 'admin-override-timeout'

    static RESERVATION_EXTENDED = 'reservation-extended'
}
import React from 'react'

export default class ScoreboardTicTacToe extends React.Component {
  scoreStyle = (index, state) => {
    if (state.length !== 0 && state[0] && state[1]) {
      if (state[0].ticTacToe[index] === 1) {
        return "cross-score"
      } else if (state[1].ticTacToe[index] === 1) {
        return "circle-score"
      }
    }

    return ""
  }

  gridText = index => {
    const gridNumbers = this.props.game.gridNumbers

    if (gridNumbers[index] !== 'BB') {
      return gridNumbers[index]
    } else {
      return <span style={{ fontSize: "0.5em", textAlign: "center" }}>Blue Ball</span>
    }
  }

  render() {
    let stateType

    if (!this.props.game.modifyGameState) {
      stateType = this.props.game.gameState
    } else {
      stateType = this.props.game.modifyGameState
    }

    return (
      <>
        <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
          <div id='score-grid' className='tic-tac-toe-scoreboard'>
            <table className='throw-count-table' style={{ width: "400px" }}>
              <tbody>
                <tr>
                  <td colSpan="2">Throws</td>
                </tr>
                <tr>
                  <td>{stateType.length > 0 && stateType[0] ? stateType[0].name : ""}</td>
                  <td>{stateType.length > 0 && stateType[1] ? stateType[1].name : ""}</td>
                </tr>
                <tr>
                  <td>{stateType.length > 0 && stateType[0] ? stateType[0].throwCount : ""}</td>
                  <td>{stateType.length > 0 && stateType[1] ? stateType[1].throwCount : ""}</td>
                </tr>
              </tbody>
            </table>
            <div className="game-score-board">
              <div className={`box-score ${this.scoreStyle(0, stateType)}`}>{this.gridText(0)}</div>
              <div className={`box-score ${this.scoreStyle(1, stateType)}`}>{this.gridText(1)}</div>
              <div className={`box-score ${this.scoreStyle(2, stateType)}`}>{this.gridText(2)}</div>
              <div className={`box-score ${this.scoreStyle(3, stateType)}`}>{this.gridText(3)}</div>
              <div className={`box-score ${this.scoreStyle(4, stateType)}`}>{this.gridText(4)}</div>
              <div className={`box-score ${this.scoreStyle(5, stateType)}`}>{this.gridText(5)}</div>
              <div className={`box-score ${this.scoreStyle(6, stateType)}`}>{this.gridText(6)}</div>
              <div className={`box-score ${this.scoreStyle(7, stateType)}`}>{this.gridText(7)}</div>
              <div className={`box-score ${this.scoreStyle(8, stateType)}`}>{this.gridText(8)}</div>
            </div>
          </div>

          {this.props.game.scoringStyle !== 'Individual' && this.props.game.currentThrower &&
            <h1 style={{fontSize: ".4rem", marginBottom: ".3rem"}}>Current Thrower: {this.props.game.currentThrower.name}</h1>
          }

          <div id="lane-number" style={{bottom: ".6rem"}}>
            <h2>{this.props.game.game}</h2>
          </div>
        </div>
      </>
    )
  }
}
